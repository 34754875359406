import { EventEmitter, Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ContactsComponent } from '../../pages/contacts/contacts.component';
import { ContactSearchModalComponent } from './modals/contact-search-modal/contact-search-modal.component';
import { DocumentFromTemplateModalComponent } from './modals/document-from-template-modal/document-from-template-modal.component';
import { DocumentUploadModalComponent } from './modals/document-upload-modal/document-upload-modal.component';
import { ProfilePictureModalComponent } from './modals/profile-picture-modal/profile-picture-modal.component';

export interface ModalAccessParams {
  type: string,
  data?: any
}

export type ActiveModal = void | ContactsComponent | any | null

@Injectable({
  providedIn: 'root'
})

export class ModalHubAccessService {
  private subject!: Subject<ModalAccessParams>;
  private closeEvent = new Subject<any>();
  public modalReady = new EventEmitter<ActiveModal>();

  constructor() { }

  closeModal(closeParams?: any) {
    this.closeEvent.next(closeParams);
   
  }

  modalClosed(): Observable<void | any> {
    this.activeModal = null;
    return this.closeEvent.asObservable();
  }
  
  openModal(modalType: ModalAccessParams) {
    this.subject.next(modalType);
    return this.modalReady;
  }

  onOpenModal(): Observable<ModalAccessParams> {
    this.subject = new Subject()
    return this.subject.asObservable();
  }

  _activeModal!: ActiveModal;
  public set activeModal (value: ActiveModal) {
    this._activeModal = value;
    this.modalReady.emit(value)
  }

  public get activeModal() {
    return this._activeModal;
  }


}
